<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Seating</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'work_from_office' }">Working From Office</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Seating</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between mb_32">
                                 <li class="page-item">
                                    <a class="d-none page-link border-0 text_black" aria-label="Previous">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </a>
                                 </li>
                                 <li class="page-item">
                                    <router-link class="page-link border-0 text_black"
                                       :to="{ name:'seating_your_desk' }" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">:</span></span>
                                          <span class="mobile_hide"> Desk</span>
                                             <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->

      <!-- Set Yourself up for success Starts -->
      <section class="set_yourself bg_grey pt_44 pb_50">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
            <div class="image_left_side">
               <img src="../assets/images/wfo_seating/set-yourself.png" alt="img">
            </div>
               </div></div>
            <div class="row justify-content-center">
               <div class="col-md-8">
                  <div class="image_text_right_side text-center">
                     <h2 class="mt_50 mb_24">It all starts with you</h2>
                     <p class="text_black mb_24 p_22">
                        No matter your chair preferences, good posture allows you to sit on
                        almost anything! But you can also defeat the best of ergo chairs with poor
                        posture and sneaky habits that you may not be aware of doing.

                     </p>
                     <div class="text-center">
                        <a href="#" class="btn btn_outline" data-bs-toggle="modal" data-bs-target="#exampleModal">
                           <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31"
                              viewBox="0 0 31 31">
                              <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                              <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff"
                                 stroke-linejoin="round" stroke-miterlimit="10">
                                 <path
                                    d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z"
                                    stroke="none"></path>
                                 <path
                                    d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z"
                                    stroke="none" fill="#fff"></path>
                              </g>
                           </svg>
                           Watch Video Now
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      <div class="text-center down_to_section newarrow">
            <a   v-scroll-to="{
     el: '#claim_space',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                              <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
        <!-- image text end -->
        <!-- Claim Your Space start -->
        <section id="claim_space" class="claim_space_sec py_100 my_14">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-11 col-xl-9 col-12">
                  <div class="claim_box d-flex">
                     <div class="claim_text_image_box">
                        <h4 class="text_primary font_bold font_size_32 mb_0">Upright</h4>
                        <h4 class="text_primary font_bold font_size_32 mb_26">and balanced </h4>
                        <img src="../assets/images/wfo_seating/s_dots.png" alt="img">
                     </div>
                     <div class="claim_text_box d-flex">
                        <div>
                           <p class="text_balticsea mb_34">
                              <strong>Alignment is key</strong>
                           </p>
                           <ul class="pl_18 mb_0 text_balticsea">
                              <li>
                                 <p>Ears align over your shoulders</p>
                              </li>
                              <li>
                                 <p>Shoulders align over your elbows</p>
                              </li>
                              <li>
                                 <p>Elbows align over your hips</p>
                              </li>
                           </ul>
                        </div>
                        <div class="common_traps ml_76">
                           <p class="text_balticsea mb_34">
                              <strong>Common posture traps</strong>
                           </p>
                           <ul class="pl_18 mb_0 text_balticsea">
                              <li>
                                 <p>Leaning chin in your hand</p>
                              </li>
                              <li>
                                 <p>Sitting too far from your keyboard</p>
                              </li>
                              <li>
                                 <p>Low screen height, causing slouching</p>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="claim_box d-flex">
                     <div class="claim_text_image_box">
                        <h4 class="text_primary font_bold font_size_32 mb_0">Know your</h4>
                        <h4 class="text_primary font_bold font_size_32 mb_26">adjustments</h4>
                        <img src="../assets/images/wfo_seating/s_chair.png" alt="img">
                     </div>
                     <div class="claim_text_box">
                        <ul class="pl_18 mb_0 text_balticsea">
                           <li>
                              <p> <strong>Set the height</strong> of your chair so that your knees are level with or
                                 slightly lower than your hips.</p>
                           </li>
                           <li>
                              <p> <strong>Adjust the back</strong> of your chair to support your lumbar (low back) area.
                              </p>
                           </li>
                           <li>
                              <p> <strong>Place your arm rest</strong> at a level that gets you close enough to your
                                 keyboard and avoids
                                 hunching your shoulders.
                              </p>
                           </li>
                           <li>
                              <p><strong>Place your feet</strong> on the floor or on a footrest. Avoid putting them on
                                 the castors or crossing
                                 you ankles with your legs extended.
                              </p>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Claim Your Space end -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More Office Tips</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'wfo_seating' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/wfo_seating/o1.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Seating</h6>
                                 <p>Learn how to setup your chair to be comfy all day long. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'seating_your_desk' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/wfo_seating/o2.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Desk </h6>
                                 <p>Learn how to best setup your desk. It’s easy!</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'seating_your_keybord' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/wfo_seating/o3.png" alt="img" class="img-fluid">

                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Keyboard &amp; Mouse</h6>
                                 <p>Keep your keyboard and mouse close to you. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'seating_your_monitor' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/wfo_seating/o4.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Monitors &amp; Screens</h6>
                                 <p>Your eyes will love the new placement of your screens. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'seating_your_meeting' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/wfo_seating/o5.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Meeting Rooms</h6>
                                 <p>You can be ergo savvy when working in meetings.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'everyday_stretches' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/wfo_seating/o6.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Everyday Stretches</h6>
                                 <p>Give your body a break with these feel good stretches. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->


        <!-- Video Modal start Here -->
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                     <iframe id="closed" src="https://player.vimeo.com/video/586041508?h=c9ff799e3a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Happy Feet"></iframe>
                  </div>
      </div>

    </div>
  </div>
</div>
<!-- Video Modal End Here -->

   </div>
</template>

<script>
   export default {
      name: "Wfo_seating",
      mounted(){ 
         if (
          localStorage.getItem("userToken") == null ||
          localStorage.getItem("employeeEmail") == null
        ) {
         this.$router.push({ name: "login" }).catch(() => true);
        }      
      },
      methods: {
          hideModal() { 
          document.getElementById('closed').src += '';
      },
      }
   }
</script>